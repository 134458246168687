*{  
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

.column-scroll-balanced {
  position: relative;
  min-height: 1px;
  padding: 0 15px;
  width: 50%;
}

.row-scroll-balanced {
  margin-left: -15px;
  margin-right: -15px;
  &:before, &:after {
    content: " ";
    display: table;
    zoom: 1;
  }
  &:after {
    clear: both;
  }
}

.scroll-balanced-left {
  float: left;
}

.scroll-balanced-right {
  float: right;
}
